import { Box, Link, Text } from "@chakra-ui/react";

function Footer() {
  return (
    <Box backgroundColor="transparent" textAlign="center" p="1em" position="sticky" bottom="0" w="100%">
      <Text color={'white'}>Powered by <Link href="https://www.forlootandglory.io" color="white" textDecoration="underline" target="_blank" rel="noopener noreferrer">For Loot & Glory</Link></Text>
    </Box>
  );
}

export default Footer;