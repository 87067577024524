import { Box, Icon, Link, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { createClient } from '@layerzerolabs/scan-client';
import { MdBlock, MdCheckCircle, MdError } from 'react-icons/md';

type Props = {
    srcTxHash: string;
    srcChainId: number;
};

enum MessageStatus {
    INFLIGHT = 'INFLIGHT',
    DELIVERED = 'DELIVERED',
    FAILED = 'FAILED',
    PAYLOAD_STORED = 'PAYLOAD_STORED',
    BLOCKED = 'BLOCKED',
    CONFIRMING = 'CONFIRMING',
}

const MessageProgress: React.FC<Props> = ({ srcTxHash, srcChainId }: { srcTxHash: string, srcChainId: number }) => {
    const [status, setStatus] = useState<MessageStatus>();
    const [txHash, setTxHash] = useState<string>();

    const fetchMessage = async (txHash: string) => {
        const layerscan = createClient('mainnet');
        const { messages } = await layerscan.getMessagesBySrcTxHash(txHash);
        if (messages.length > 0) {
            setStatus(messages[0].status);
        }
    };

    useEffect(() => {
        let urlHash = window.location.hash.substring(2); // remove the '#' at the start
        if (!urlHash && srcTxHash) {
            window.location.hash = `/${srcTxHash}`;
            urlHash = srcTxHash;
        }
        const txHash_ = srcTxHash || urlHash;
        if (!txHash_) {
            return;
        }
        setTxHash(txHash_);

        fetchMessage(txHash_)
        // Définit un interval pour rafraîchir le message toutes les minutes
        const intervalId = setInterval(() => fetchMessage(txHash_), 60000);

        // Nettoie l'intervalle lors du démontage du composant
        return () => clearInterval(intervalId);
    }, [srcTxHash, srcChainId]);

    const renderStatus = (status: MessageStatus | undefined) => {
        switch (status) {
            case MessageStatus.INFLIGHT:
                return (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Spinner />
                        <Text color={'white'}>The transfer is being transmitted.</Text>
                    </Box>
                );
            case MessageStatus.PAYLOAD_STORED:
                return (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Spinner />
                        <Text color={'white'}>The payload has been stored.</Text>
                    </Box>
                );
            case MessageStatus.BLOCKED:
                return (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Icon as={MdBlock} />
                        <Text color={'white'}>The transfer is blocked.</Text>
                    </Box>
                );
            case MessageStatus.CONFIRMING:
                return (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Spinner />
                        <Text color={'white'}>The transfer is being confirmed.</Text>
                    </Box>
                );
            case MessageStatus.DELIVERED:
                return (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Icon as={MdCheckCircle} />
                        <Text color={'white'}>The transfer has been delivered.</Text>
                    </Box>
                );
            case MessageStatus.FAILED:
                return (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Icon as={MdError} />
                        <Text color={'white'}>The transfer has failed.</Text>
                    </Box>
                );
            default:
                return <Text color={'white'}>Ready to Bridge.</Text>;
        }
    };

    return (
        <Box
            marginY={4}
            width={{ base: "100%", md: "80%", lg: "60%" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            {status ? renderStatus(status)
                :
                null}
            {txHash && (
                <>
                    <Link href={`https://layerzeroscan.com/tx/${txHash}`} isExternal>
                        View transaction on LayerZeroScan
                    </Link>
                    <Text fontSize="sm" marginTop="2" textAlign="center">
                        Remember to bookmark this page or the LayerZero explorer link to not lose track of your transfer.
                    </Text>
                </>
            )}
        </Box>
    );
};

export default MessageProgress;