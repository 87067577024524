import { Box, Image } from "@chakra-ui/react";
import ConnectWallet from "./connectwallet";
import img from '../../static/mHeading.png';

function Header() {
  return (
    <Box>
      <ConnectWallet />
      <Box display="flex" justifyContent="center" alignItems="center" h="30vh">
        <Image src={img} alt="For Loot & Glory" w="50%" h="auto" />
      </Box>
    </Box>
  );
}

export default Header;