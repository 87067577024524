import React, { useEffect, useState } from 'react'; // Étape 1: Importez useEffect et useState
import { Box } from "@chakra-ui/react";
import Header from './web3/components/header';
import BridgeForm from './web3/components/bridgeform';
import Footer from './web3/components/footer';
import background from './static/background_island.png';
import { useWallet } from "./web3/context/hooks/walletcontext";
import ChainSelector from './web3/components/chainSelector';

function App() {
  const { isWrongChain } = useWallet();
  const [showBridgeForm, setShowBridgeForm] = useState(false);

  useEffect(() => {
    if (!isWrongChain) {
      setShowBridgeForm(true); // Affichez BridgeForm si les conditions sont remplies
    } else {
      setShowBridgeForm(false); // Sinon, ne l'affichez pas
    }
  }, [isWrongChain]); // Dépendances de useEffect

  return (
    <Box minH="100vh" display={'flex'} justifyContent="space-between" flexDirection={'column'} flexGrow={1} bgImage={background} bgSize={'cover'}>
      <Header />
      {showBridgeForm ? <BridgeForm /> : <ChainSelector />} 
      <Footer />
    </Box>
  );
}

export default App;