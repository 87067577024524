import { EndpointId } from "@layerzerolabs/lz-definitions";
import { ChainType } from "../chains";

export const skaleEuropa: ChainType = {
  id: 2_046_399_126,
  name: 'SKALE Europa',
  logoURI: 'logo/europa.png',
  nativeCurrency: { name: 'sFUEL', symbol: 'sFUEL', decimals: 18 },
  isTestnet: false,
  layerzero: { eid: EndpointId.SKALE_V2_MAINNET, feestoken: '0xe0595a049d02b7674572b0d59cd4880db60edc50', feesSymbols: 'SKL' },
  rpcUrls: {
    default: {
      http: ['https://mainnet.skalenodes.com/v1/elated-tan-skat'],
      webSocket: ['wss://mainnet.skalenodes.com/v1/ws/elated-tan-skat'],
    },
  },
  blockExplorers: {
    default: {
      name: 'SKALE Explorer',
      url: 'https://elated-tan-skat.explorer.mainnet.skalenodes.com',
    },
  },
}