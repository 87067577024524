import React, { useEffect } from 'react';
import { useWallet } from '../context/hooks/walletcontext';
import { Modal, Flex, IconButton, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Box, Stack, Text, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, Image, Icon } from "@chakra-ui/react";
import { chainIds, chains } from '../context/chains/chains';
import { BiTransfer } from 'react-icons/bi';
import { polygon, sepolia } from 'viem/chains';


const ConnectWallet: React.FC = () => {
    const { account, chainId, status, error, isLoading, isWrongChain, connectWallet, disconnectWallet, switchChain, gasBalance } = useWallet();

    const [currentChainId, setCurrentChainId] = React.useState<number | null>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    useEffect(() => {
        if (chainId) {
            setCurrentChainId(chainId);
        }
    }
        , [chainId, status, account]);

    const handleConnectClick = async () => {
        await connectWallet();
    };

    const handleDisconnectClick = async () => {
        await disconnectWallet();
    };

    const handleChainChange = async (newChainId: number) => {
        setCurrentChainId(newChainId);
        await switchChain(newChainId.toString());
    };

    const popover = (
        <Popover closeOnBlur={true}>
            <PopoverTrigger>
                {account && <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} >{account.toString().substring(0, 6)}...{account.toString().substring(account.toString().length - 4)}</Button>}
            </PopoverTrigger>
            <PopoverContent>
                <PopoverHeader>Wallet Summary</PopoverHeader>
                <PopoverBody boxShadow="0px 4px 10px rgba(0, 0, 0, 0.7)" bgImage="linear-gradient(to right, #0095B6, #00B6D0)">
                    <Box>
                        <Box flexDirection="column">
                            <Box>
                                <Text as="strong">Address:</Text> {account ? `${account.toString().substring(0, 6)}...${account.toString().substring(account.toString().length - 4)}` : ''}
                            </Box>
                            <Box>
                                <Text as="strong">Gas Balance:</Text> {gasBalance}
                            </Box>
                        </Box>
                        <Button onClick={handleDisconnectClick} isLoading={isLoading}>
                            {isLoading ? 'Disconnecting...' : 'Disconnect Wallet'}
                        </Button>
                    </Box>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );

    const filteredChainIds = chainIds.filter(id => id.toString() === polygon.id.toString() || id.toString() === sepolia.id.toString());

    const modal = (
        <Modal isOpen={isOpen} onClose={handleClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select a Chain</ModalHeader>
                <ModalCloseButton />
                <ModalBody boxShadow="0px 4px 10px rgba(0, 0, 0, 0.7)" bgImage="linear-gradient(to right, #0095B6, #00B6D0)">
                    <Stack spacing={4} direction={["column", "row"]} wrap="wrap">
                        {filteredChainIds.map((id, key) =>
                            <Box key={key}>
                                <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} leftIcon={<Image src={chains[id].logoURI} boxSize="20px" />} value={chains[id].id} onClick={() => handleChainChange(chains[id].id)}>
                                    <Text>{chains[id].name}</Text>
                                </Button>
                            </Box>
                        )}
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );

    return (
        <Flex direction={["column", "row"]} align="center">
            {status === 'disconnect' ? (
                <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} onClick={handleConnectClick} isLoading={isLoading}>
                    {isLoading ? 'Connecting...' : 'Connect Wallet'}
                </Button>
            ) : (!isWrongChain ? popover : <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} className="error" onClick={handleOpen}>Incorrect Network</Button>)}
            <Flex direction={["column", "row"]} align="center" w={["100%", "200px"]} justifyContent="space-between">
                <IconButton
                    color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }}
                    aria-label="Select chain"
                    icon={currentChainId && chains[currentChainId] ? <Image src={chains[currentChainId].logoURI} alt={chains[currentChainId].name} width="30" height="30" /> : <Icon as={BiTransfer} />}
                    onClick={handleOpen}
                />
                {modal}
            </Flex>
            {error && <Box className="error">{error}</Box>}
        </Flex>
    );
}

export default ConnectWallet;