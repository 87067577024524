import { EndpointId } from "@layerzerolabs/lz-definitions";
import { polygon } from "./polygon";
import { skaleEuropa } from "./skale/europa";

export interface ChainType {
  id: number;
  name: string;
  logoURI: string;
  nativeCurrency: {
    decimals: number;
    name: string;
    symbol: string;
  };
  isTestnet: boolean;
  layerzero: {
    eid: EndpointId;
    feestoken: string;
    feesSymbols: string;
  }
  rpcUrls: {
    default: {
      http: string[];
      webSocket?: string[];
    };
  };
  blockExplorers: {
    default: {
      name: string;
      url: string;
      apiUrl?: string;
    };
  };
}

export interface ArrayChainType {
  [key: string]: ChainType;
}

export const chains: ArrayChainType = {
  [skaleEuropa.id.toString()]: skaleEuropa,
  [polygon.id.toString()]: polygon,
};

export const chainIds = Object.keys(chains).map((chainId) => parseInt(chainId));