import { Box, Button, Input, Text, Grid, Icon } from "@chakra-ui/react";
import { useBridge } from "../context/hooks/bridgecontext";
import MessageProgress from "./bridgechecker";
import { useWallet } from "../context/hooks/walletcontext";
import { GoArrowRight } from "react-icons/go";
import { chains } from "../context/chains/chains";

function BridgeForm() {

  const {
    amount,
    sourceToken,
    srcChainId,
    dstChainId,
    sourceTokenBalance,
    isApprove,
    sendParamsFee,
    hash,
    handleApprove,
    handleBridge,
    setAmount,
    handleSrcChainChange, // Assuming this method exists in your context to change the source chain
    handleDstChainChange, // Assuming this method exists in your context to change the destination chain
  } = useBridge();

  const { status } = useWallet();

  const invertBridgeDirection = () => {
    const tempSrcChainId = srcChainId;
    handleSrcChainChange(dstChainId); // Change source to current destination
    handleDstChainChange(tempSrcChainId); // Change destination to current source
  };

  const checkApproval = () => {
    // ((!isApprove && Number(amount) > 0) && sourceToken.addresses[srcChainId].isAdapter ) 
    if (!sourceToken.addresses[srcChainId].isAdapter) return true;
    else if (!isApprove && Number(amount) > 0) return false
    else return true
  }

  return (
    <Box boxShadow="0px 4px 10px rgba(0, 0, 0, 0.7)" background="linear-gradient(to right, #0095B6, #00B6D0)" p={["1em", "2em"]} borderRadius="10px" m="1em auto" maxWidth={["100%", "600px"]} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Text color="white" mb="1em" textAlign="center">Bridge your token from {chains[srcChainId].name} to {chains[dstChainId].name}</Text>
      {status !== 'disconnect' ?
        <Box p={["1em", "2em"]} borderRadius="10px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box flex="1" mr="1em" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb="1em">
            <Text color="white">FLAG</Text>
          </Box>
          <Grid templateColumns={["1fr", "1fr auto 1fr"]} gap="1em" alignItems="center" justifyItems="center" w="100%" mb="1em">
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Text color="white">{chains[srcChainId].name}</Text>
            </Box>
            <Icon as={GoArrowRight} onClick={invertBridgeDirection} cursor="pointer" />
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Text color="white">{chains[dstChainId].name}</Text>
            </Box>
          </Grid>
          <Input
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Amount to bridge"
            p="1em"
            w="100%"
            mb="1em"
            borderRadius="5px"
            border="1px solid #ccc"
            _placeholder={{ color: 'white' }}
          />
          <Text color={'white'} mb="1em">Your balance: {sourceTokenBalance} {sourceToken.symbol}</Text>
          {!checkApproval() &&
            <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} onClick={handleApprove} isDisabled={Number(amount) <= 0} mb="1em">Approve Tokens</Button>}
          {sendParamsFee.nativeFees && <Text mb="1em">Estimated Gas Fee: {sendParamsFee.nativeFees} {chains[srcChainId].layerzero.feesSymbols}</Text>}
          <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} onClick={handleBridge} isDisabled={!isApprove || !sendParamsFee.nativeFees} mb="1em">Bridge</Button>
          <MessageProgress srcTxHash={hash} srcChainId={srcChainId} />
        </Box> :
        <Text color="white">Please connect your wallet</Text>
      }
    </Box>
  );
}

export default BridgeForm;