import { ethers, JsonRpcProvider, JsonRpcSigner } from 'ethers';
import TokenABI from "./OFTabi";
import ERC20ABI from "./ERC20abi";
import { Options } from "@layerzerolabs/lz-v2-utilities";
import { TokenType } from './context/tokens/tokens';
import { ChainType } from './context/chains/chains';
import { FLAG } from './context/tokens/flag';
import { polygon } from './context/chains/polygon';
import { skaleEuropa } from './context/chains/skale/europa';

// Fonction de pontage des tokens
export async function bridgeTokens(signer: JsonRpcSigner, sendParams: any, nativeFees: string, token: TokenType, srcChainId: number) {
    const tokenAddress = token.addresses[srcChainId]?.address;
    const adapterAddress = token.addresses[srcChainId]?.OFTAdapter;
    if (adapterAddress) {
        const contract = new ethers.Contract(adapterAddress, TokenABI, signer);
        try {
            const tx = await contract.send(sendParams, [ethers.parseEther(nativeFees), 0], await signer.getAddress(), { value: ethers.parseEther(nativeFees) });
            await tx.wait();
            return tx;
        } catch (error) {
            console.error("Error during bridging: ", error);
        }
    } else if (tokenAddress) {
        const contract = new ethers.Contract(tokenAddress, TokenABI, signer);
        try {
            const tx = await contract.send(sendParams, [ethers.parseEther(nativeFees), 0], await signer.getAddress());
            await tx.wait();
            return tx;
        } catch (error) {
            console.error("Error during bridging: ", error);
        }
    }
}

export interface SendParamsFee {
    sendParams: any;
    nativeFees: string;
}

// Get the fees for the token bridge
export async function quoteSend(signer: JsonRpcSigner, amount: string, token: TokenType, srcChainId: number, dstChain: ChainType) {
    const tokenAddress = token.addresses[srcChainId]?.address;
    const adapterAddress = token.addresses[srcChainId]?.OFTAdapter;
    const isAdapter = token.addresses[srcChainId]?.isAdapter;
    const provider = getSrcProvider(srcChainId)
    console.log("isAdapter: ", isAdapter);
    console.log("dstChain : " + dstChain.id)
    console.log("srcChainId : " + srcChainId)
    if (!amount) {
        amount = "1";
    }
    if (adapterAddress && isAdapter) {
        const contract = new ethers.Contract(adapterAddress, TokenABI, provider);
        const options = Options.newOptions().addExecutorLzReceiveOption(2000000, 0).toHex().toString();

        const sendParams = [
            dstChain.layerzero.eid,
            ethers.zeroPadValue(await signer.getAddress(), 32),
            ethers.parseUnits(amount.toString(), "ether"),
            ethers.parseUnits(amount.toString(), "ether"),
            options,
            "0x",
            "0x"
        ];

        const [nativeFees] = await contract.quoteSend(sendParams, false);
        console.log("quoteSend: ", nativeFees);
        const result: SendParamsFee = {
            sendParams,
            nativeFees: ethers.formatUnits(nativeFees, "ether")
        };
        console.log("result: ", result);
        return result;
    } else if (tokenAddress) {
        const contract = new ethers.Contract(tokenAddress, TokenABI, provider);
        const options = Options.newOptions().addExecutorLzReceiveOption(2000000, 0).toHex().toString();

        const sendParams = [
            dstChain.layerzero.eid,
            ethers.zeroPadValue(await signer.getAddress(), 32),
            ethers.parseUnits(amount.toString(), "ether"),
            ethers.parseUnits(amount.toString(), "ether"),
            options,
            "0x",
            "0x"
        ];

        const [nativeFees] = await contract.quoteSend(sendParams, false);
        console.log("quoteSend: ", nativeFees);
        const result: SendParamsFee = {
            sendParams,
            nativeFees: ethers.formatUnits(nativeFees, "ether")
        };
        console.log("result: ", result);
        return result;

    }
}

// Approuver les tokens avant le pontage
export async function approveTokens(signer: JsonRpcSigner, amount: string, token: TokenType, chainId: number) {
    const tokenAddress = token.addresses[chainId]?.address;
    const adapterAddress = token.addresses[chainId]?.OFTAdapter;
    if (adapterAddress) {
        const contract = new ethers.Contract(tokenAddress, ERC20ABI, signer);
        try {
            const tx = await contract.approve(adapterAddress, ethers.parseUnits(amount, "ether"));
            await tx.wait();
            return tx;
        } catch (error) {
            console.error("Error during token approval: ", error);
        }
    } else if (tokenAddress) {
        return true
    }
}

export const getSrcProvider = (chainId: number) => {
    return chainId === polygon.id ? new JsonRpcProvider(polygon.rpcUrls.default.http.toString()) : new JsonRpcProvider(skaleEuropa.rpcUrls.default.http.toString())
}

export const approveSKLForBridge = async (signer: JsonRpcSigner, amount: string) => {
    const contract = new ethers.Contract(skaleEuropa.layerzero.feestoken, ERC20ABI, signer);
    try {
        const allowance = await contract.allowance(await signer.getAddress(), FLAG.addresses[skaleEuropa.id].address)
        if (allowance < ethers.parseUnits(amount, "ether")) {
            const tx = await contract.approve(FLAG.addresses[skaleEuropa.id].address, ethers.parseUnits(amount, "ether"));
            await tx.wait();
            return true;
        } else {
            return true
        }
    } catch (error) {
        console.error("Error during token approval: ", error);
    }
}

export async function isApproved(signer: JsonRpcSigner, amount: string, token: TokenType, chainId: number) {
    const provider = getSrcProvider(chainId)
    const tokenAddress = token.addresses[chainId]?.address;
    const adapterAddress = token.addresses[chainId]?.OFTAdapter;
    const isAdapter = token.addresses[chainId]?.isAdapter;
    let contract;
    if (isAdapter && (tokenAddress && adapterAddress)) {
        contract = new ethers.Contract(tokenAddress, ERC20ABI, provider);
        const approvedAmount = await contract.allowance(await signer.getAddress(), adapterAddress);
        return approvedAmount >= ethers.parseUnits(amount, "ether");
    } else {
        return true;
    }
}

// Obtenir le solde du token de l'utilisateur
export async function getTokenBalance(signer: JsonRpcSigner, token: TokenType, chainId: number) {
    const provider = getSrcProvider(chainId)
    const tokenAddress = token.addresses[chainId]?.address;
    let contract;
    if (tokenAddress) {
        contract = new ethers.Contract(tokenAddress, ERC20ABI, provider);
        const balance = await contract.balanceOf(await signer.getAddress());
        return ethers.formatEther(balance);
    }
}
