import { extendTheme } from "@chakra-ui/react";
import { color } from "framer-motion";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "'IM Fell English', serif",
        backgroundColor: "#0095B6", // Pale navy blue
        color: "white", // Black for text
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold",
        textTransform: "uppercase",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.7)",
        bg: "linear-gradient(to right, #0095B6, #00B6D0)", // Utilisez `bg`
        _hover: {
          bg: "#FFA500", // Utilisez `bg` pour le hover également
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          backgroundColor: "#0095B6", // Pale navy blue
          color: "white", // Black for text
        },
      },
    },
    Popover: {
      baseStyle: {
        content: {
          backgroundColor: "#0095B6", // Pale navy blue
          color: "white", // Black for text
        },
      },
    },
  },
});

export default theme;