import React, { createContext, useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { chains, ChainType } from '../chains/chains';


interface WalletContextType {
    account: ethers.AddressLike | null;
    signer: ethers.JsonRpcSigner | null;
    gasBalance: string;
    chainId: number | null;
    chain: ChainType | null;
    isWrongChain: boolean | null;
    status: string;
    error: string | null;
    isLoading: boolean;
    connectWallet: () => Promise<void>;
    disconnectWallet: () => Promise<void>;
    switchChain: (chainId: string) => Promise<void>;
}

interface CustomError extends Error {
    code: number;
}

const WalletContext = createContext<WalletContextType>({
    account: null,
    signer: null,
    gasBalance: '0',
    chainId: null,
    chain: null,
    isWrongChain: false,
    status: 'disconnect',
    error: null,
    isLoading: false,
    connectWallet: async () => { throw new Error("WalletProvider not mounted"); },
    disconnectWallet: async () => { throw new Error("WalletProvider not mounted"); },
    switchChain: async (chainId: string) => { throw new Error("WalletProvider not mounted"); }
});

interface WalletProviderProps {
    children: React.ReactNode;
}

export const WalletProvider: React.FC<WalletProviderProps> = ({ children }) => {
    const [account, setAccount] = useState<ethers.AddressLike | null>(null);
    const [signer, setSigner] = useState<ethers.JsonRpcSigner | null>(null);
    const [chain, setChain] = useState<ChainType | null>(null);
    const [chainId, setChainId] = useState<number | null>(null);
    const [status, setStatus] = useState<string>('disconnect');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isWrongChain, setIsWrongChain] = useState<boolean>(false);
    const [gasBalance, setGasBalance] = useState<string>('0');

    const connectWallet = async () => {
        setIsLoading(true);
        setError(null);
        if (!window.ethereum) {
            setError("Ethereum is not available");
            setIsLoading(false);
            return;
        }
        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            setSigner(signer);
            const account = signer.address;
            setAccount(account);
            setStatus('connect');

            // Vérifier si le réseau est correct
            await checkChain();

            // Sauvegarder le compte connecté dans le stockage local
            localStorage.setItem('lastConnectedWallet', account);
        } catch (error) {
            setError("Failed to connect wallet");
            console.error(error);
        }
        setIsLoading(false);
    };

    const disconnectWallet = async () => {
        setIsLoading(true);
        setError(null);
        if (!window.ethereum) {
            setError("Ethereum is not available");
            setIsLoading(false);
            return;
        }
        try {
            await window.ethereum.request({
                method: "eth_requestAccounts",
                params: [{ eth_accounts: {} }]
            })
            setAccount(null);
            setChainId(null);
            setStatus('disconnect');

            // Supprimer le compte connecté du stockage local
            localStorage.removeItem('lastConnectedWallet');
        } catch (error) {
            setError("Failed to disconnect wallet");
            console.error(error);
        }
        setIsLoading(false);
    };

    const switchChain = async (chainId: string) => {
        console.log('switchChain', chainId);
        if (!window.ethereum) {
            return;
        }
    
        // Convertir l'identifiant de chaîne en format hexadécimal
        const hexChainId = `0x${parseInt(chainId, 10).toString(16)}`;
    
        try {
            await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: hexChainId }] });
        } catch (switchError) {
            // Ce code d'erreur indique que la chaîne n'a pas été ajoutée à MetaMask.
            if ((switchError as CustomError).code === 4902) {
                const chainData = {
                    chainId: hexChainId,
                    chainName: chains[chainId].name,
                    nativeCurrency: chains[chainId].nativeCurrency,
                    rpcUrls: chains[chainId].rpcUrls.default.http,
                    blockExplorerUrls: [chains[chainId].blockExplorers.default.url] // Mettre l'URL dans un tableau
                };
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [chainData],
                    });
                    // Réessayer de basculer la chaîne après l'ajout
                    await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: hexChainId }] });
                } catch (addError) {
                    console.error("Failed to add chain: ", addError);
                }
            } else {
                console.error("Failed to switch chain: ", switchError);
            }
        }
        await checkChain();
    }

    const checkChain = async () => {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();
        const newChainId = Number(network.chainId);
        setChainId(newChainId);
        const chain_ = chains[newChainId];
        if (chain_) {
            setChain(chain_);
        } else {
            setIsWrongChain(true);
        }
    }


    // Gestionnaires d'événements pour les changements de chaîne, de compte et de connexion
    const handleChainChanged = async (_chainId: string) => {
        window.location.reload();
        await checkChain();

    };

    const handleConnect = async () => {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        setSigner(signer);
        const account = signer.address;
        setAccount(account);
        // Vérifier si le réseau est correct
        await checkChain();
        setStatus('connect');
    }

    const handleAccountsChanged = async () => {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        setSigner(signer);
        const account = signer.address;
        setAccount(account);
    }

    const HandleReconnect = async () => {
        const lastConnectedWallet = localStorage.getItem('lastConnectedWallet');
        if (lastConnectedWallet && window.ethereum) {
            try {
                const provider = new ethers.BrowserProvider(window.ethereum);
                const signer = await provider.getSigner();
                // Vérifier si le réseau est correct
                await checkChain();
                setSigner(signer);
                const account = signer.address;
                if (account === lastConnectedWallet) {
                    setAccount(account);
                    setStatus('connect');
                } else {
                    // Gérer l'erreur de reconnexion

                }
            } catch (error) {
                // Gérer l'erreur de reconnexion
            }
        }
    };

    // Ecouté la balance de gas
    useEffect(() => {
        const provider = new ethers.BrowserProvider(window.ethereum);
        let intervalId: any;

        if (provider && account && chain) {
            const fetchGasBalance = async () => {
                const gasBalance = await provider.getBalance(account);
                // Convertir le solde de gas en Ether
                const gasBalanceInEther = Number(ethers.formatEther(gasBalance));
                setGasBalance(gasBalanceInEther.toFixed(4) + ` ${chain.nativeCurrency.name}`);
            };

            fetchGasBalance();
            // Vérifier le solde de gas toutes les 10 secondes
            intervalId = setInterval(fetchGasBalance, 10000);
        }

        // Nettoyer l'intervalle lors du démontage du composant
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
        // eslint-disable-next-line
    }, [chainId, account]);

    useEffect(() => {
        if (!window.ethereum) {
            return;
        } else {
            window.ethereum.on('connect', handleConnect);
            window.ethereum.on('chainChanged', handleChainChanged);
            window.ethereum.on('accountsChanged', handleAccountsChanged);
        }

        // Fonction de nettoyage
        return () => {
            if (window.ethereum) {
                window.ethereum.removeListener('connect', handleConnect);
                window.ethereum.removeListener('chainChanged', handleChainChanged);
                window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            }
        };
        // eslint-disable-next-line
    }, [status]);

    useEffect(() => {
        HandleReconnect();
        // eslint-disable-next-line
    }, [status]);

    const value = { account, signer, chainId, chain, isWrongChain, status, error, isLoading, connectWallet, disconnectWallet, switchChain, gasBalance };

    return (
        <WalletContext.Provider value={value}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => useContext(WalletContext);