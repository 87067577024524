import { EndpointId } from "@layerzerolabs/lz-definitions";
import { ChainType } from "./chains";

export const polygon: ChainType = {
  id: 137,
  name: 'Polygon',
  logoURI: 'logo/polygon.png',
  nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  isTestnet: false,
  layerzero: { eid: EndpointId.POLYGON_V2_MAINNET, feestoken: '0x', feesSymbols: 'MATIC'},
  rpcUrls: {
    default: {
      http: ['https://polygon-rpc.com'],
    },
  },
  blockExplorers: {
    default: {
      name: 'PolygonScan',
      url: 'https://polygonscan.com',
      apiUrl: 'https://api.polygonscan.com/api',
    },
  }
};