import React from 'react';
import { useWallet } from '../context/hooks/walletcontext';
import { Box, Button, Image, Stack, Text } from '@chakra-ui/react';
import { chainIds, chains } from '../context/chains/chains';
// Importez d'autres bibliothèques si nécessaire

function ChainSelector() {

    const { switchChain } = useWallet();

    const handleChainChange = async (newChainId: number) => {
        await switchChain(newChainId.toString());
    };
    return (
        <Box boxShadow="0px 4px 10px rgba(0, 0, 0, 0.7)" background="linear-gradient(to right, #0095B6, #00B6D0)" p={["1em", "2em"]} borderRadius="10px" m="1em auto" maxWidth={["100%", "600px"]} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Stack spacing={4} direction={["column", "row"]} wrap="wrap" align="center" justify="center">
                {chainIds.map((id, key) =>
                    <Box key={key}>
                        <Button leftIcon={<Image src={chains[id].logoURI} boxSize="20px" />} value={chains[id].id} onClick={() => handleChainChange(chains[id].id)}>
                            <Text>{chains[id].name}</Text>
                        </Button>
                    </Box>
                )}
            </Stack>
        </Box>
    );
}

export default ChainSelector;